import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import onstore from "../../Assets/Projects/onstore.png";
import naxlar from "../../Assets/Projects/narxlar.png";
import transaction from "../../Assets/Projects/transactions.png";
import watch from "../../Assets/Projects/watch.png";
import UmeetSchool from "../../Assets/Projects/Umeet.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import Dostlik from "../../Assets/Projects/Dostlik.png";
import GreenShop from "../../Assets/Projects/GreenShop.png";
import Edteach from "../../Assets/Projects/Edteach.png";
import Jquruvchi from "../../Assets/Projects/Jquruvchi.png";
import SafoSavdo from "../../Assets/Projects/SafoSavdo.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={GreenShop}
              isBlog={false}
              title="Flowers Online Store"
              ghLink=""
              demoLink="https://green-shop-full-original.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={onstore}
              isBlog={false}
              title="Furnitures Online Store"
              ghLink="https://github.com/ShahriyorDeveloper/magazin"
              demoLink="https://magazin-shahriyordeveloper.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Edteach}
              isBlog={false}
              title="Edteach Quiz App"
              ghLink=""
              demoLink="https://quiz-app-finish-nk6g.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={transaction}
              isBlog={false}
              title="Transaction App"
              ghLink="https://github.com/ShahriyorDeveloper/1---Transaction-"
              demoLink="https://1-transaction.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={watch}
              isBlog={false}
              title="Watch App"
              ghLink="https://github.com/ShahriyorDeveloper/Secondomer-Watch"
              demoLink="https://secondomer-watch.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Dostlik}
              isBlog={false}
              title="Do'stlik Region official Site"
              ghLink=""
              demoLink="https://www.dustlik.uz/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={naxlar}
              isBlog={false}
              title="Price Compare App "
              ghLink="https://github.com/ShahriyorDeveloper/narxlar.com"
              demoLink="https://narxlar-com.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={UmeetSchool}
              isBlog={false}
              title="Ummet School Education Center"
              ghLink=""
              demoLink="https://www.umeetschool.uz/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={SafoSavdo}
              isBlog={false}
              title="SafoSavdo CRM Project"
              ghLink=""
              demoLink="https://safosavdo.uz/sign-in"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Jquruvchi}
              isBlog={false}
              title="Jquruvchi Building Firma Site"
              ghLink=""
              demoLink="https://jquruvchi-rouge.vercel.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
