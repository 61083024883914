import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiGithub,
  DiGit,
  DiHtml5,
} from "react-icons/di";
import { FaCss3, FaBootstrap } from "react-icons/fa";

import {
  SiFirebase,
  SiRedux,
  SiTailwindcss,
  SiTypescript,
  SiAntdesign
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
        <p style={{ fontSize: "18px" }}>HTML</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <p style={{ fontSize: "18px" }}>JavaScript</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAntdesign />
        <p style={{ fontSize: "18px" }}>Antdesign</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTypescript />
        <p style={{ fontSize: "18px" }}>Typescript</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaCss3 />
        <p style={{ fontSize: "18px" }}>CSS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <p style={{ fontSize: "18px" }}>React</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaBootstrap />
        <p style={{ fontSize: "18px" }}>Bootstrap</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTailwindcss />
        <p style={{ fontSize: "18px" }}>Tailwindcss</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <p style={{ fontSize: "18px" }}>Git</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <p style={{ fontSize: "18px" }}>Firebase</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGithub />
        <p style={{ fontSize: "18px" }}>Github</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedux />
        <p style={{ fontSize: "18px" }}>Redux</p>
      </Col>
    </Row>
  );
}

export default Techstack;
