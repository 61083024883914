import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiHeroku,
  SiVercel,
  SiNetlify,
  SiPrettier,
  SiEslint
} from "react-icons/si";
import { FaFigma } from "react-icons/fa";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <FaFigma />
        <p style={{ fontSize: "18px" }}>Figma</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <p style={{ fontSize: "18px" }}>Visualstudiocode</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <p style={{ fontSize: "18px" }}>Postman</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
        <p style={{ fontSize: "18px" }}>Vercel</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiEslint />
        <p style={{ fontSize: "18px" }}>Eslint</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPrettier />
        <p style={{ fontSize: "18px" }}>Prettier</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNetlify />
        <p style={{ fontSize: "18px" }}>Netlify</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHeroku />
        <p style={{ fontSize: "18px" }}>Heroku</p>
      </Col>
    </Row>
  );
}

export default Toolstack;
